<template>
    <div class="materialSupply">
        <el-select
            v-model="stationId"
            placeholder="请选择"
            @change="selectStation"
        >
            <el-option
                v-for="item in allStationData"
                :key="item.station_id"
                :label="item.station_name"
                :value="item.station_id"
            >
            </el-option>
        </el-select>
        <ul class="taskStatistics">
            <li>
                <h4>供应单：</h4>
                <p class="scrollNumbers scrollNumbers1">
                    <span id="supplyOrder"></span><i class="unit">个</i>
                </p>
            </li>
            <li>
                <h4>运输车次：</h4>
                <p class="scrollNumbers scrollNumbers2">
                    <span id="trainNumber"></span><i class="unit">次</i>
                </p>
            </li>
            <li>
                <h4>采购量：</h4>
                <p class="scrollNumbers scrollNumbers3">
                    <span id="supplied_count"></span><i class="unit">吨</i>
                </p>
            </li>
        </ul>
        <div class="rawMaterial">
            <div class="table-wrapper">
                <div class="supplyTable">
                    <el-table
                        border
                        ref="ref_supplyTable"
                        :data="supplyTableData"
                        class="tableStyle"
                        height="100%"
                        highlight-current-row
                        @row-click="handleCurrentChange"
                    >
                        <el-table-column
                            prop="type_name"
                            label="原材料类型"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="supplied_count"
                            label="采购量(吨)"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="count"
                            label="进场车次(次)"
                        >
                        </el-table-column>
                    </el-table>
                </div>
                <div class="carTable">
                    <el-table
                        border
                        height="100%"
                        ref="ref_logisticsTrainnumber"
                        :data="supplyLogisticsStatistics"
                        v-scroll="scrollFn"
                        class="tableStyle"
                    >
                        <el-table-column
                            prop="plate_number"
                            label="车牌号"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="goods_name"
                            label="材料"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="source_name"
                            label="货源地"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="station_name"
                            label="供应场站"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="gross_weight"
                            label="毛重"
                            width="50"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="tare_weight"
                            label="皮重"
                            width="50"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="net_weight"
                            label="净重"
                            width="50"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="gross_time"
                            label="进场时间"
                            width="120"
                        >
                        </el-table-column>
                        <el-table-column
                            label="状态"
                            width="60"
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.transport_status == 0 && scope.row.balance_number == '' ">运输中</span>
                                <span v-else-if="scope.row.status == 0">进场</span>
                                <span v-else-if="scope.row.status == 1">完成</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div id="rawMaterialCharts" class="chartsStyle"></div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/common/eventBus.ts';
import largeScreenFun from './JS/index.js';
export default {
    name: 'material-supply',
    components: {},
    directives: {
        scroll: {
            inserted: function (el, binding) {
                const cb = binding.value;
                const selectWrap = el.querySelector('.el-table__body-wrapper');
                selectWrap.addEventListener('mousewheel', function (e) {
                    const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
                    const direction = e.deltaY > 0 ? 'down' : 'up';
                    cb(selectWrap, direction, scrollDistance);
                });
            },
        },
    },
    props: {},
    data() {
        return {
            time: '',
            tenantId: '',
            stationId: '',
            stationData: [],
            allStationData: [],
            // 物资供应情况列表数据
            supplyTableData: [],
            // 物资供应情况列表选中
            currentRow: 0,
            myChart: null,

            statisData: {
                nocount: 0,
                count: 0,
                supplied_count: 0,
            },
            supplyLogisticsStatistics: [],
            searchVal: '',
            // 列表分页下标
            tablePage: 0,
            // 列表总条数
            total: 10000,
            // 是否是最后一页
            isLastPage: false,
            loading: false,
        };
    },
    watch: {
        statisData(val, oldVal) {
            if (val.nocount !== oldVal.nocount) {
                largeScreenFun.electronicNumber('#supplyOrder', val.nocount || 0);
            }
            if (val.count !== oldVal.count) {
                largeScreenFun.electronicNumber('#trainNumber', val.count || 0);
            }
            if (val.supplied_count !== oldVal.supplied_count) {
                largeScreenFun.electronicNumber('#supplied_count', val.supplied_count || 0);
            }
        },
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        async initPage() {
            this.time = this.$parent.time;
            this.allStationData = this.$parent.allStationData;
            this.stationId = this.allStationData[0].station_id;
            this.tenantId = this.allStationData[0].tenantId;
            this.myChart = this.$echarts.init(document.getElementById('rawMaterialCharts'));
            this.currentRow = 0;
            await this.loadPage();
        },
        async loadPage() {
            const supplyStatistics = await largeScreenFun.getSupplyStatistics(this.time, this.stationId, this.tenantId);
            this.statisData = supplyStatistics;
            this.initsupplyStatistics();
            const list = await largeScreenFun.getSupplyStatistics_rawMaterial(this.time, this.stationId, this.tenantId);
            this.supplyTableData = list.map((item, index) => {
                item.index = index;
                return item;
            });
            if (this.supplyTableData.length > 0) {
                this.handleCurrentChange(this.supplyTableData[this.currentRow]);
                this.currentRow += 1;
                if (this.currentRow === this.supplyTableData.length) {
                    this.currentRow = 0;
                }
            } else {
                this.myChart.clear();
            }

            // 获取供应物流车次
            this.supplyLogisticsStatistics
                = await largeScreenFun.getSupplyLogisticsStatistics(this.time, this.tenantId, this.stationId, '', this.tablePage);
        },
        // 鼠标滚动加载更多
        async scrollFn(obj, direction, scrollTop) {
            if (direction === 'up'
                || this.loading === true
                || this.isLastPage === true
            ) {return;}
            if (scrollTop <= 120) {
                if (this.supplyLogisticsStatistics.length < this.total) {
                    this.tablePage++;
                    this.loading = true;
                    const list = await largeScreenFun.getSupplyLogisticsStatistics(this.time, this.tenantId, this.stationId, '', this.tablePage);
                    this.supplyLogisticsStatistics.push(...list);
                    this.$nextTick(() => {
                        this.$refs.ref_logisticsTrainnumber.doLayout();
                    });
                    this.loading = false;
                } else {
                    this.isLastPage = true;
                }
            }
        },
        // 切换场站
        selectStation(station_id) {
            this.currentRow = 0;
            const selectItem = this.allStationData.filter(item => item.station_id === station_id)[0];
            this.tenantId = selectItem.tenantId;
            this.stationId = selectItem.station_id;
            this.loadPage();
            eventBus.$emit('updateMonitor', this.tenantId, this.stationId);
        },
        // 加载物资供应情况统计数据
        initsupplyStatistics() {
            largeScreenFun.electronicNumber('#supplyOrder', this.statisData.nocount || 0);
            largeScreenFun.electronicNumber('#trainNumber', this.statisData.count || 0);
            largeScreenFun.electronicNumber('#supplied_count', this.statisData.supplied_count || 0);
        },

        // 物质供应情况列表选择
        handleCurrentChange(row) {
            this.currentRow = row.index;
            this.$refs.ref_supplyTable.setCurrentRow(row);
            this.getSupplyChartData(row);

            // 设置当前点击行始终出现在可视区域
            try {
                const vmEl = this.$refs.ref_supplyTable.$el;
                const targetTop = vmEl.querySelectorAll('.el-table__body tr')[this.currentRow].getBoundingClientRect().top;
                const conTop = vmEl.querySelector('.el-table__body').getBoundingClientRect().top;
                const height = vmEl.querySelector('.el-table__body-wrapper').getBoundingClientRect().height - 38;
                if (targetTop - conTop - height > this.$refs.ref_supplyTable.bodyWrapper.scrollTop) {
                    this.$refs.ref_supplyTable.bodyWrapper.scrollTop = targetTop - conTop;
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}

        },

        async getSupplyChartData(data) {
            const row = await largeScreenFun.getSupplyStatistics_chart(data.type_dic_code, this.time, this.tenantId);
            const chartData = {
                xAxis: {
                    data: [],
                },
                yAxis: {
                    name: '(吨)',
                    nameTextStyle: [0, 0, -7, 0],
                },
                series: [
                    {
                        name: '计划采购量',
                        barWidth: 36,
                        color: [ '#76E0A8', '#13BD63'],
                        data: [],
                    },
                ],
            };
            row.forEach(item => {
                chartData.xAxis.data.push(item.station_name);
                chartData.series[0].data.push(item.supply_count);
                // chartData.series[1].data.push(item.supplied_count);
            });
            this.columnDiagram(chartData);
        },


        // 柱图
        columnDiagram(data) {
            const option = {
                tooltip: {},
                grid: {
                    top: 50,
                    left: 60,
                    bottom: 30,
                    right: 0,
                },
                xAxis: {
                    data: data.xAxis.data,
                    axisLine: {
                        show: true,
                        color: 'rgba(255,255,255,0.1)',
                    },
                    splitArea: {
                        color: '#f00',
                        lineStyle: {
                            color: '#f00',
                        },
                    },
                    axisLabel: {
                        color: 'rgba(255,255,255,0.5)',
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        name: '(吨)',
                        nameTextStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            fontSize: 12,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisLine: {
                            show: true,
                            color: 'rgba(255,255,255,0.1)',
                        },
                        axisLabel: {
                            show: true,
                            color: 'rgba(255,255,255,0.5)',
                        },
                        axisTick: {
                            show: false,
                        },
                        animationDuration: 300,
                        animationDurationUpdate: 300,
                    },
                ],
                series: [

                ],
            };
            data.series.forEach(item => {
                // let newSymbolOffset = [];
                // if (index === 0) {
                //     newSymbolOffset = [-16, 10];
                // } else {
                //     newSymbolOffset = [16, 10];
                // }
                option.series.push({
                    name: item.name,
                    type: 'bar',
                    barWidth: item.barWidth,
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 1, [{
                                offset: 0,
                                color: item.color[0],
                            },
                            {
                                offset: 1,
                                color: item.color[1],
                            },
                            ]),
                        },
                    },
                    data: item.data,
                    z: 10,
                    zlevel: 0,
                    label: {
                        show: true,
                        position: 'top',
                        distance: 5,
                        fontSize: '18',
                        fontFamily: 'Technology-Regular, Technology',
                        color: '#13BD63',
                    },
                    animationDuration: 0,
                    animationDurationUpdate: 1000,
                    // animationEasing: 'linear',
                    // animationEasingUpdate: 'linear',
                },
                {
                    // 分隔
                    type: 'pictorialBar',
                    itemStyle: {
                        normal: {
                            color: '#0F375F',
                        },
                    },
                    symbolRepeat: 'fixed',
                    symbolMargin: 3,
                    symbol: 'rect',
                    symbolClip: true,
                    symbolSize: [36, 2],
                    // symbolOffset: newSymbolOffset,
                    data: item.data,
                    zlevel: 1,
                    animationDuration: 0,
                    animationDurationUpdate: 1000,
                }
                );
            });
            this.myChart.setOption(option);
        },
    },
};
</script>

<style scoped lang="stylus">
.materialSupply
    width 100%;
    .taskStatistics
        padding 0.15rem;
    .rawMaterial
        width 100%;
        display flex
        flex-direction column
        .table-wrapper
            width 100%;
            display flex
            height 2.4rem;
            >>> .el-table
                th
                    background #1B3153;
                    box-shadow: 0rem 0rem 0.06rem 0rem #4A74AB inset;
                th.el-table__cell.is-leaf
                    border-bottom 0
            .supplyTable
                width 4rem
                margin-right 0.15rem
            .carTable
                width: calc(100% - 4.16rem);
                >>> td
                    color rgba(255,255,255,0.8)
        #rawMaterialCharts
            width 100%;
            flex 1
</style>
